$grey: #aaaaaa;
$border-gradient: linear-gradient(120deg, #00ff00, #25a1af 50%, #aa00ff);
$avatar-size: 5em;
$dos-font: "More Perfect DOS VGA", "Courier", monospace;
$text-font: "Roboto Slab", "Tahoma", sans-serif;
$body-padding: 8px;
$card-padding: 1rem;
$button-border: 3px;
$maxwidth: calc(100vw - 2 * $body-padding - 2 * $card-padding - 2 * $button-border);
$button-width: min(25rem, $maxwidth);

html {
  display: flex;
  min-height: 100%;
  background-color: black;

  background-image: url("../img/background.webp");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  background-attachment: fixed;
}

body {
  flex: 1;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  margin: 0;
  padding: $body-padding;
}

#center-card {
  margin-top: calc($avatar-size / 2);

  border-radius: 25px;
  box-shadow: 2px 2px 20px #030303;

  color: $grey;

  padding: 0 $card-padding $card-padding;
  min-width: calc($button-width + 2 * $button-border);

  $backdrop-filter: blur(20px);
  @supports (backdrop-filter: $backdrop-filter) or (-webkit-backdrop-filter: $backdrop-filter) {
    backdrop-filter: $backdrop-filter;
    background-color: #30303070;
  }
  @supports not (backdrop-filter: $backdrop-filter) {
    background-color: #303030;
  }

  header {
    display: flex;
    justify-content: space-around;
    margin-bottom: .25rem;

    #avatar-border {

      display: flex;
      padding: 3px;
      border-radius: $avatar-size;
      margin-top: calc($avatar-size / -2);

      background-image: $border-gradient;
      box-shadow: 1px 1px 10px black;

      #avatar {

        width: $avatar-size;
        height: $avatar-size;

        border-radius: $avatar-size;
      }
    }
  }

  main {
    margin-bottom: 1rem;

    #title {
      font-family: $dos-font;
      font-size: 18pt;
      font-smooth: never;
      -webkit-font-smoothing: none;
      text-decoration: none;
      font-weight: normal;
      text-align: center;
      margin: .5rem 0 0;

      .green {
        color: lime;
      }

      .grey {
        color: $grey;
      }
    }

    #buttons {
      $gap: .5em;
      display: flex;
      flex-direction: column;
      gap: $gap;
      padding-top: $gap;

      .button {
        display: block;
        background-color: $grey;
        color: $grey;
        border: none;
        border-radius: 8px;
        padding: $button-border;
        width: $button-width;

        font-size: 12pt;
        font-smooth: never;
        -webkit-font-smoothing: none;
        font-family: $dos-font;
        cursor: pointer;

        transition-duration: .25s;

        &:hover {
          color: #00ff00;
          background-image: $border-gradient;
          box-shadow: 1px 1px 5px #101010;
        }

        .text {
          border-radius: 6px;
          background-color: black;
          padding-top: 5px;
          padding-bottom: 5px;

          display: flex;
          flex-direction: row;
          align-items: center;

          .icon-container {
            width: 3rem;
            display: flex;
            justify-content: center;
            align-items: center;

            .icon {
              width: auto;
              height: 1rem;
              text-align: center;
              filter: invert(75%);
            }
          }

          .label {
            flex: 1;
            padding-right: 3em;
            text-align: center;
          }

        }
      }
    }
  }

  footer {
    padding-left: .25rem;
    font-size: 8pt;
    font-family: $text-font;
    display: flex;
    justify-content: space-between;

    .center {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      text-align: center;
      pointer-events: none;

      a {
        pointer-events: all;
      }
    }

    a {
      text-decoration: underline;
    }
  }

  .embed {
    width: min-content;
    max-width: calc(100vw - (2 * $body-padding + 2 * $card-padding));
    display: inline-block;

    overflow: auto;
    font-family: $text-font;
  }
}

a {
  color: unset;
  text-decoration: unset;

  &:hover {
    color: lime;
  }
}
