html {
  background-color: #000;
  background-image: url("background.07965bf7.webp");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100%;
  display: flex;
}

body {
  flex-flow: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  margin: 0;
  padding: 8px;
  display: flex;
}

#center-card {
  color: #aaa;
  border-radius: 25px;
  min-width: calc(min(25rem, 100vw - 22px - 2rem) + 6px);
  margin-top: 2.5em;
  padding: 0 1rem 1rem;
  box-shadow: 2px 2px 20px #030303;
}

@supports ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) or ((-webkit-backdrop-filter: blur(20px))) {
  #center-card {
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background-color: #30303070;
  }
}

@supports not ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
  #center-card {
    background-color: #303030;
  }
}

#center-card header {
  justify-content: space-around;
  margin-bottom: .25rem;
  display: flex;
}

#center-card header #avatar-border {
  background-image: linear-gradient(120deg, #0f0, #25a1af 50%, #a0f);
  border-radius: 5em;
  margin-top: -2.5em;
  padding: 3px;
  display: flex;
  box-shadow: 1px 1px 10px #000;
}

#center-card header #avatar-border #avatar {
  border-radius: 5em;
  width: 5em;
  height: 5em;
}

#center-card main {
  margin-bottom: 1rem;
}

#center-card main #title {
  font-smooth: never;
  -webkit-font-smoothing: none;
  text-align: center;
  margin: .5rem 0 0;
  font-family: More Perfect DOS VGA, Courier, monospace;
  font-size: 18pt;
  font-weight: normal;
  text-decoration: none;
}

#center-card main #title .green {
  color: #0f0;
}

#center-card main #title .grey {
  color: #aaa;
}

#center-card main #buttons {
  flex-direction: column;
  gap: .5em;
  padding-top: .5em;
  display: flex;
}

#center-card main #buttons .button {
  color: #aaa;
  font-smooth: never;
  -webkit-font-smoothing: none;
  cursor: pointer;
  background-color: #aaa;
  border: none;
  border-radius: 8px;
  width: min(25rem, 100vw - 22px - 2rem);
  padding: 3px;
  font-family: More Perfect DOS VGA, Courier, monospace;
  font-size: 12pt;
  transition-duration: .25s;
  display: block;
}

#center-card main #buttons .button:hover {
  color: #0f0;
  background-image: linear-gradient(120deg, #0f0, #25a1af 50%, #a0f);
  box-shadow: 1px 1px 5px #101010;
}

#center-card main #buttons .button .text {
  background-color: #000;
  border-radius: 6px;
  flex-direction: row;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}

#center-card main #buttons .button .text .icon-container {
  justify-content: center;
  align-items: center;
  width: 3rem;
  display: flex;
}

#center-card main #buttons .button .text .icon-container .icon {
  text-align: center;
  filter: invert(75%);
  width: auto;
  height: 1rem;
}

#center-card main #buttons .button .text .label {
  text-align: center;
  flex: 1;
  padding-right: 3em;
}

#center-card footer {
  justify-content: space-between;
  padding-left: .25rem;
  font-family: Roboto Slab, Tahoma, sans-serif;
  font-size: 8pt;
  display: flex;
}

#center-card footer .center {
  text-align: center;
  pointer-events: none;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
}

#center-card footer .center a {
  pointer-events: all;
}

#center-card footer a {
  text-decoration: underline;
}

#center-card .embed {
  width: min-content;
  max-width: calc(100vw + -16px - 2rem);
  font-family: Roboto Slab, Tahoma, sans-serif;
  display: inline-block;
  overflow: auto;
}

a {
  color: unset;
  -webkit-text-decoration: unset;
  text-decoration: unset;
}

a:hover {
  color: #0f0;
}

/*# sourceMappingURL=index.3011086b.css.map */
